import React from 'react';
import ReactDOM from 'react-dom';
import { UserAgent } from 'react-useragent';
import App from './App';
import PleaseUseYourMobilePhone from './PleaseUseYourMobilePhone';
import { Layout } from './ui';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'sanitize.css';

const Root = () => (
  <UserAgent>
    {({ ua }) => {
      if (!ua.mobile) {
        return <PleaseUseYourMobilePhone />;
      }

      return <App />;
    }}
  </UserAgent>
);

ReactDOM.render(
  <Layout>
    <Root />
  </Layout>,
  document.getElementById('root'),
);

serviceWorker.unregister();
