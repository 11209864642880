import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Axios from 'axios';
import { get } from 'lodash';
import { camelizeKeys } from 'humps';
import { Title, Description } from './ui';

const NOT_FOUND = 'NOT_FOUND';

const getPlaceInvitations = id =>
  Axios.get(`${process.env.REACT_APP_API_BASE}/v1/place_invitations/${id}`);

const InputsGroup = styled.div`
  margin-left: -24px;
  margin-bottom: 16px;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  width: 1em;
  padding: 0.25em;
  font-size: 2.5em;
  font-family: monospace;
  text-align: center;
  outline: none;
  margin-left: 24px;
  margin-top: 24px;
  line-height: 1.6em;
  width: 3rem;

  ${props =>
    props.hasError &&
    css`
      border-color: #e22a2a;
    `}
`;

const Button = styled.button`
  height: 48px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  appearance: none;
  outline: none;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  background-color: #009de0;
  font-weight: 600;
  box-shadow: 0 4px 8px 0 #c2e4f2;

  ${props =>
    props.disabled &&
    css`
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.38);
      box-shadow: none;
    `}
`;

const App = () => {
  const [values, setValues] = useState(['', '', '', '']);
  const [resultByDigits, setResultByDigits] = useState({});
  const currentResult = resultByDigits[values.join('')];
  const notFound = currentResult === NOT_FOUND;
  const currentResultUrl = get(currentResult, 'inviteUrl');

  const one = useRef();
  const two = useRef();
  const three = useRef();
  const four = useRef();

  const focusAndSelectInput = index => {
    switch (index) {
      case 0:
        one.current.focus();
        one.current.select();
        break;
      case 1:
        two.current.focus();
        two.current.select();
        break;
      case 2:
        three.current.focus();
        three.current.select();
        break;
      case 3:
        four.current.focus();
        four.current.select();
        break;
      default:
        return;
    }
  };

  const updateDigit = index => e => {
    const value = e.target.value.replace(/[^\d]/g, '');
    let focusedIndex;

    if (
      isNaN(Number(value)) ||
      (Boolean(value) && values[index] && values[index + 1])
    ) {
      return;
    }

    let newValues = [...values];
    newValues[index] = value[0];

    if (value.length > 1) {
      newValues[index + 1] = value[1] || values[index + 1];
    }

    if (value.length > 2) {
      newValues[index + 2] = value[2] || values[index + 2];
    }

    if (value.length > 3) {
      newValues[index + 3] = value[3] || values[index + 3];
    }

    if (Boolean(value)) {
      focusedIndex = Math.min(index + value.length, 3);
    }

    focusAndSelectInput(focusedIndex);
    setValues(newValues.slice(0, 4));
  };

  const updateKeyDown = index => e => {
    let newValues = [...values];
    let focusedIndex;

    if (['Backspace', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) > -1) {
      e.preventDefault();
    }

    if (e.key === 'ArrowLeft') {
      focusedIndex = Math.max(0, index - 1);
    }

    if (e.key === 'ArrowRight') {
      focusedIndex = Math.min(3, index + 1);
    }

    if (e.key === 'Backspace') {
      focusedIndex = index;
      newValues[index] = '';

      while (focusedIndex > 0 && !Boolean(newValues[focusedIndex])) {
        focusedIndex--;
      }
    }

    focusAndSelectInput(focusedIndex);
    setValues(newValues.slice(0, 4));
  };

  const fetchData = async digits => {
    try {
      const { data } = await getPlaceInvitations(digits);
      setResultByDigits({ ...resultByDigits, [digits]: camelizeKeys(data) });
    } catch (e) {
      if (get(e, 'response.status') === 404) {
        setResultByDigits({ ...resultByDigits, [digits]: NOT_FOUND });
      }
    }
  };

  useEffect(() => {
    if (values.filter(Boolean).length !== 4) {
      return;
    }

    fetchData(values.join(''));
  }, [values.join('')]);

  return (
    <>
      <Title>Bienvenue</Title>

      <Description>
        L’activation de votre compte commence… Saisissez le code d’activation
        que l’on vous a donné pendant l’assemblée générale.
      </Description>

      <InputsGroup>
        <Input
          ref={one}
          type="tel"
          value={values[0] || ''}
          onChange={updateDigit(0)}
          onKeyDown={updateKeyDown(0)}
          hasError={notFound}
        />

        <Input
          ref={two}
          type="tel"
          value={values[1] || ''}
          onChange={updateDigit(1)}
          onKeyDown={updateKeyDown(1)}
          hasError={notFound}
        />

        <Input
          ref={three}
          type="tel"
          value={values[2] || ''}
          onChange={updateDigit(2)}
          onKeyDown={updateKeyDown(2)}
          hasError={notFound}
        />

        <Input
          ref={four}
          type="tel"
          value={values[3] || ''}
          onChange={updateDigit(3)}
          onKeyDown={updateKeyDown(3)}
          hasError={notFound}
        />
      </InputsGroup>

      {notFound && (
        <Description variant="error">
          Le code indiqué ne correspond à aucun lieu. Modifiez-le ou contactez
          votre gestionnaire sergic pour l’obtenir.
        </Description>
      )}

      <Button
        disabled={!Boolean(currentResultUrl)}
        as={Boolean(currentResultUrl) ? 'a' : null}
        href={currentResultUrl}
      >
        Valider
      </Button>
    </>
  );
};

export default App;
