import React from 'react';
import styled from 'styled-components';
import { IoIosPhonePortrait } from 'react-icons/io';
import { Title, Description } from './ui';

const Icon = styled.div`
  font-size: 96px;
  text-align: center;
`;

const PleaseUseYourMobilePhone = () => (
  <>
    <Icon>
      <IoIosPhonePortrait />
    </Icon>
    <Title>Merci d’utiliser votre téléphone</Title>
    <Description>
      Le Welcome Process requiert l’utilisation d’un téléphone portable.
    </Description>
  </>
);

export default PleaseUseYourMobilePhone;
