import React from 'react';
import styled, { css } from 'styled-components';
import logo from './logo.svg';
import background from './background.jpg';

export const viewportSizes = {
  desktop: 1366,
  medium: 1100,
  tablet: 800,
  tabletMini: 500,
};

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tabletMini: mediaQuery`(min-width: ${viewportSizes.tabletMini / 16}em)`,
  tablet: mediaQuery`(min-width: ${viewportSizes.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${viewportSizes.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${viewportSizes.desktop / 16}em)`,
};

export const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  text-align: center;

  ${media.tablet`
    justify-content: center;
    min-height: 100vh;
    background-image: url('${background}');
    background-size: cover;
    background-position: center center;
  `}
`;

export const Logo = styled.img`
  display: block;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;

  ${media.tablet`
    margin-top: 0;
  `}
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
`;

export const Description = styled.p`
  margin-top: 0;
  margin-bottom: 0;

  ${props =>
    props.variant === 'error' &&
    css`
      color: #e22a2a;
    `}
`;

const Box = styled.div`
  ${media.tablet`
    width: 640px;
    max-width: 100%;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12),
      0 24px 48px 0 rgba(0, 0, 0, 0.12);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const Layout = ({ children }) => (
  <Wrapper>
    <Box>
      <Logo src={logo} alt="éseis Syndic" />

      {children}
    </Box>
  </Wrapper>
);
